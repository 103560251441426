/**
 * Define all `PhraseApp` keys (in sorted order)
 */
export const localeKeys = {
  accountMultipleError: 'accountMultipleError',
  accountNameErrorRequired: 'accountNameErrorRequired',
  accountNumber: 'accountNumber',
  accountSelectionTitle: 'accountSelectionTitle',
  accountSwitchKit: 'accountSwitchKit',
  accountType: 'accountType',
  acknowledge: 'acknowledge',
  activateText: 'activateText',
  active: 'active',
  activeText: 'activeText',
  add: 'add',
  addCreditorCategoryText: 'addCreditorCategoryText',
  addCreditorText: 'addCreditorText',
  address: 'address',
  addressCityText: 'addressCityText',
  addressCountryText: 'addressCountryText',
  addressErrorCityRequired: 'addressErrorCityRequired',
  addressErrorCountryCodeRequired: 'addressErrorCountryCodeRequired',
  addressErrorNameRequired: 'addressErrorNameRequired',
  addressErrorNumberRequired: 'addressErrorNumberRequired',
  addressErrorPostalCodeRequired: 'addressErrorPostalCodeRequired',
  addressErrorStreetRequired: 'addressErrorStreetRequired',
  addressNumberText: 'addressNumberText',
  addressPostalCodeText: 'addressPostalCodeText',
  addressStreetText: 'addressStreetText',
  affectedContractsHelperText: 'affectedContractsHelperText',
  affectedContractsText: 'affectedContractsText',
  alreadyAccountText: 'alreadyAccountText',
  alreadySignedUp: 'alreadySignedUp',
  amountErrorInvalid: 'amountErrorInvalid',
  amountErrorInvalidFormat: 'amountErrorInvalidFormat',
  amountErrorMax: 'amountErrorMax',
  amountErrorMin: 'amountErrorMin',
  amountErrorRequired: 'amountErrorRequired',
  amountText: 'amountText',
  amountVaries: 'amountVaries',
  appearance: 'appearance',
  appMenuChangeEmailPasswordText: 'appMenuChangeEmailPasswordText',
  appMenuChangeLanguageText: 'appMenuChangeLanguageText',
  appMenuHelloText: 'appMenuHelloText',
  appMenuHelp: 'appMenuHelp',
  appMenuLanguageText: 'appMenuLanguageText',
  appMenuOverviewText: 'appMenuOverviewText',
  appMenuPersonalDetailsText: 'appMenuPersonalDetailsText',
  appMenuSignOut: 'appMenuSignOut',
  appMenuSignOutText: 'appMenuSignOutText',
  ariaLabelToggleVisibility: 'ariaLabelToggleVisibility',
  backText: 'backText',
  backToDirectDebits: 'backToDirectDebits',
  backToIncomingPayments: 'backToIncomingPayments',
  backToLogin: 'backToLogin',
  backToOverview: 'backToOverview',
  backToTransferBalance: 'backToTransferBalance',
  balanceAccountErrorRequired: 'balanceAccountErrorRequired',
  balanceText: 'balanceText',
  bankNotFoundText: 'bankNotFoundText',
  bankNotSupported: 'bankNotSupported',
  bankSortCodeExcluded: 'bankSortCodeExcluded',
  bankSelectionNewBankAsOldBankError: 'bankSelectionNewBankAsOldBankError',
  bankText: 'bankText',
  bankUpdateNote: 'bankUpdateNote',
  beneficiaryText: 'beneficiaryText',
  bicText: 'bicText',
  business: 'business',
  buttonLaunchMfa: 'buttonLaunchMfa',
  buttonNext: 'buttonNext',
  cancel: 'cancel',
  cancelStandingOrderTitle: 'cancelStandingOrderTitle',
  changeEmail: 'changeEmail',
  changePassword: 'changePassword',
  claveDeAccesoText: 'claveDeAccesoText',
  closableHintConfirmButtonText: 'closableHintConfirmButtonText',
  closeAccountBalanceAccountNameInput: 'closeAccountBalanceAccountNameInput',
  closeAccountBalanceIbanInput: 'closeAccountBalanceIbanInput',
  closeAccountBalanceNegativeCheckboxText: 'closeAccountBalanceNegativeCheckboxText',
  closeAccountBalanceOptions1: 'closeAccountBalanceOptions1',
  closeAccountBalanceOptions2: 'closeAccountBalanceOptions2',
  closeAccountButton: 'closeAccountButton',
  closeAccountConfirmationText: 'closeAccountConfirmationText',
  closeAccountConfirmationTitle: 'closeAccountConfirmationTitle',
  closeAccountDateOfAccountCloseCaption: 'closeAccountDateOfAccountCloseCaption',
  closeAccountDateOfAccountCloseTitle: 'closeAccountDateOfAccountCloseTitle',
  closeAccountDelayAccountCloseText: 'closeAccountDelayAccountCloseText',
  closeAccountDirectDebitText: 'closeAccountDirectDebitText',
  closeAccountFormTitle: 'closeAccountFormTitle',
  closeAccountHeadingText: 'closeAccountHeadingText',
  closeAccountIncomingPaymentsText: 'closeAccountIncomingPaymentsText',
  closeAccountInfoItemDetails1: 'closeAccountInfoItemDetails1',
  closeAccountInfoItemDetails2: 'closeAccountInfoItemDetails2',
  closeAccountInfoItemDetails3: 'closeAccountInfoItemDetails3',
  closeAccountInfoItemDetails4: 'closeAccountInfoItemDetails4',
  closeAccountInfoItemHeading1: 'closeAccountInfoItemHeading1',
  closeAccountInfoItemHeading2: 'closeAccountInfoItemHeading2',
  closeAccountInfoItemHeading3: 'closeAccountInfoItemHeading3',
  closeAccountInfoItemHeading4: 'closeAccountInfoItemHeading4',
  closeAccountInfoTitle: 'closeAccountInfoTitle',
  closeAccountStandingOrdersText: 'closeAccountStandingOrdersText',
  closeAccountSuccessCaption: 'closeAccountSuccessCaption',
  closeAccountSuccessTitle: 'closeAccountSuccessTitle',
  closeAccountText: 'closeAccountText',
  closeAccountTitle: 'closeAccountTitle',
  closeAccountTransferBalanceText: 'closeAccountTransferBalanceText',
  closeAccountValidityDateTitle: 'closeAccountValidityDateTitle',
  closeButton: 'closeButton',
  completeTransferText: 'completeTransferText',
  completedText: 'completedText',
  companyAuthorizationConfirmation: 'companyAuthorizationConfirmation',
  companyDetails: 'companyDetails',
  companyDetailsCaption: 'companyDetailsCaption',
  companyEmail: 'companyEmail',
  companyName: 'companyName',
  companyTaxId: 'companyTaxId',
  confirm: 'confirm',
  confirmPasswordErrorRequired: 'confirmPasswordErrorRequired',
  confirmPasswordText: 'confirmPasswordText',
  confirmation: 'confirmation',
  congratulationsText: 'congratulationsText',
  continueText: 'continueText',
  cookiesConsent: 'cookiesConsent',
  cookiesConsentText: 'cookiesConsentText',
  countryAfghanistan: 'countryAfghanistan',
  countryAlandIslands: 'countryAlandIslands',
  countryAlbania: 'countryAlbania',
  countryAlgeria: 'countryAlgeria',
  countryAmericanSamoa: 'countryAmericanSamoa',
  countryAndorra: 'countryAndorra',
  countryAngola: 'countryAngola',
  countryAnguilla: 'countryAnguilla',
  countryAntarctica: 'countryAntarctica',
  countryAntiguaAndBarbuda: 'countryAntiguaAndBarbuda',
  countryArgentina: 'countryArgentina',
  countryArmenia: 'countryArmenia',
  countryAruba: 'countryAruba',
  countryAustralia: 'countryAustralia',
  countryAustria: 'countryAustria',
  countryAzerbaijan: 'countryAzerbaijan',
  countryBahamas: 'countryBahamas',
  countryBahrain: 'countryBahrain',
  countryBangladesh: 'countryBangladesh',
  countryBarbados: 'countryBarbados',
  countryBelarus: 'countryBelarus',
  countryBelgium: 'countryBelgium',
  countryBelize: 'countryBelize',
  countryBenin: 'countryBenin',
  countryBermuda: 'countryBermuda',
  countryBhutan: 'countryBhutan',
  countryBolivia: 'countryBolivia',
  countryBosniaAndHerzegovina: 'countryBosniaAndHerzegovina',
  countryBotswana: 'countryBotswana',
  countryBouvetIsland: 'countryBouvetIsland',
  countryBrazil: 'countryBrazil',
  countryBritishIndianOceanTerritory: 'countryBritishIndianOceanTerritory',
  countryBruneiDarussalam: 'countryBruneiDarussalam',
  countryBulgaria: 'countryBulgaria',
  countryBurkinaFaso: 'countryBurkinaFaso',
  countryBurundi: 'countryBurundi',
  countryCambodia: 'countryCambodia',
  countryCameroon: 'countryCameroon',
  countryCanada: 'countryCanada',
  countryCapeVerde: 'countryCapeVerde',
  countryCaymanIslands: 'countryCaymanIslands',
  countryCentralAfricanRepublic: 'countryCentralAfricanRepublic',
  countryChad: 'countryChad',
  countryChile: 'countryChile',
  countryChina: 'countryChina',
  countryChristmasIsland: 'countryChristmasIsland',
  countryCocosKeelingIslands: 'countryCocosKeelingIslands',
  countryColombia: 'countryColombia',
  countryComoros: 'countryComoros',
  countryCongo: 'countryCongo',
  countryCongoDemocraticRepublic: 'countryCongoDemocraticRepublic',
  countryCookIslands: 'countryCookIslands',
  countryCostaRica: 'countryCostaRica',
  countryCoteDIvoire: 'countryCoteDIvoire',
  countryCroatia: 'countryCroatia',
  countryCuba: 'countryCuba',
  countryCuracao: 'countryCuracao',
  countryCyprus: 'countryCyprus',
  countryCzechRepublic: 'countryCzechRepublic',
  countryDenmark: 'countryDenmark',
  countryDjibouti: 'countryDjibouti',
  countryDominica: 'countryDominica',
  countryDominicanRepublic: 'countryDominicanRepublic',
  countryEcuador: 'countryEcuador',
  countryEgypt: 'countryEgypt',
  countryElSalvador: 'countryElSalvador',
  countryEquatorialGuinea: 'countryEquatorialGuinea',
  countryEritrea: 'countryEritrea',
  countryErrorRequired: 'countryErrorRequired',
  countryEstonia: 'countryEstonia',
  countryEthiopia: 'countryEthiopia',
  countryFalklandIslandsMalvinas: 'countryFalklandIslandsMalvinas',
  countryFaroeIslands: 'countryFaroeIslands',
  countryFiji: 'countryFiji',
  countryFinland: 'countryFinland',
  countryFrance: 'countryFrance',
  countryFrenchGuiana: 'countryFrenchGuiana',
  countryFrenchPolynesia: 'countryFrenchPolynesia',
  countryFrenchSouthernTerritories: 'countryFrenchSouthernTerritories',
  countryGabon: 'countryGabon',
  countryGambia: 'countryGambia',
  countryGeorgia: 'countryGeorgia',
  countryGermany: 'countryGermany',
  countryGhana: 'countryGhana',
  countryGibraltar: 'countryGibraltar',
  countryGreece: 'countryGreece',
  countryGreenland: 'countryGreenland',
  countryGrenada: 'countryGrenada',
  countryGuadeloupe: 'countryGuadeloupe',
  countryGuam: 'countryGuam',
  countryGuatemala: 'countryGuatemala',
  countryGuernsey: 'countryGuernsey',
  countryGuinea: 'countryGuinea',
  countryGuineaBissau: 'countryGuineaBissau',
  countryGuyana: 'countryGuyana',
  countryHaiti: 'countryHaiti',
  countryHeardIslandMcdonaldIslands: 'countryHeardIslandMcdonaldIslands',
  countryHolySeeVaticanCityState: 'countryHolySeeVaticanCityState',
  countryHonduras: 'countryHonduras',
  countryHongKong: 'countryHongKong',
  countryHungary: 'countryHungary',
  countryIceland: 'countryIceland',
  countryIndia: 'countryIndia',
  countryIndonesia: 'countryIndonesia',
  countryInvalidText: 'countryInvalidText',
  countryIran: 'countryIran',
  countryIraq: 'countryIraq',
  countryIreland: 'countryIreland',
  countryIsleOfMan: 'countryIsleOfMan',
  countryIsrael: 'countryIsrael',
  countryItaly: 'countryItaly',
  countryJamaica: 'countryJamaica',
  countryJapan: 'countryJapan',
  countryJersey: 'countryJersey',
  countryJordan: 'countryJordan',
  countryKazakhstan: 'countryKazakhstan',
  countryKenya: 'countryKenya',
  countryKiribati: 'countryKiribati',
  countryKorea: 'countryKorea',
  countryKosovo: 'countryKosovo',
  countryKuwait: 'countryKuwait',
  countryKyrgyzstan: 'countryKyrgyzstan',
  countryLaoPeoplesDemocraticRepublic: 'countryLaoPeoplesDemocraticRepublic',
  countryLatvia: 'countryLatvia',
  countryLebanon: 'countryLebanon',
  countryLesotho: 'countryLesotho',
  countryLiberia: 'countryLiberia',
  countryLibyanArabJamahiriya: 'countryLibyanArabJamahiriya',
  countryLiechtenstein: 'countryLiechtenstein',
  countryLithuania: 'countryLithuania',
  countryLuxembourg: 'countryLuxembourg',
  countryMacao: 'countryMacao',
  countryMacedonia: 'countryMacedonia',
  countryMadagascar: 'countryMadagascar',
  countryMalawi: 'countryMalawi',
  countryMalaysia: 'countryMalaysia',
  countryMaldives: 'countryMaldives',
  countryMali: 'countryMali',
  countryMalta: 'countryMalta',
  countryMarshallIslands: 'countryMarshallIslands',
  countryMartinique: 'countryMartinique',
  countryMauritania: 'countryMauritania',
  countryMauritius: 'countryMauritius',
  countryMayotte: 'countryMayotte',
  countryMexico: 'countryMexico',
  countryMicronesia: 'countryMicronesia',
  countryMoldova: 'countryMoldova',
  countryMonaco: 'countryMonaco',
  countryMongolia: 'countryMongolia',
  countryMontenegro: 'countryMontenegro',
  countryMontserrat: 'countryMontserrat',
  countryMorocco: 'countryMorocco',
  countryMozambique: 'countryMozambique',
  countryMyanmar: 'countryMyanmar',
  countryNamibia: 'countryNamibia',
  countryNauru: 'countryNauru',
  countryNepal: 'countryNepal',
  countryNetherlands: 'countryNetherlands',
  countryNetherlandsAntilles: 'countryNetherlandsAntilles',
  countryNewCaledonia: 'countryNewCaledonia',
  countryNewZealand: 'countryNewZealand',
  countryNicaragua: 'countryNicaragua',
  countryNiger: 'countryNiger',
  countryNigeria: 'countryNigeria',
  countryNiue: 'countryNiue',
  countryNorfolkIsland: 'countryNorfolkIsland',
  countryNorthKorea: 'countryNorthKorea',
  countryNorthernMarianaIslands: 'countryNorthernMarianaIslands',
  countryNorway: 'countryNorway',
  countryOman: 'countryOman',
  countryPakistan: 'countryPakistan',
  countryPalau: 'countryPalau',
  countryPalestinianTerritoryOccupied: 'countryPalestinianTerritoryOccupied',
  countryPanama: 'countryPanama',
  countryPapuaNewGuinea: 'countryPapuaNewGuinea',
  countryParaguay: 'countryParaguay',
  countryPeru: 'countryPeru',
  countryPhilippines: 'countryPhilippines',
  countryPitcairn: 'countryPitcairn',
  countryPoland: 'countryPoland',
  countryPortugal: 'countryPortugal',
  countryPuertoRico: 'countryPuertoRico',
  countryQatar: 'countryQatar',
  countryReunion: 'countryReunion',
  countryRomania: 'countryRomania',
  countryRussianFederation: 'countryRussianFederation',
  countryRwanda: 'countryRwanda',
  countrySaintBarthelemy: 'countrySaintBarthelemy',
  countrySaintHelena: 'countrySaintHelena',
  countrySaintKittsAndNevis: 'countrySaintKittsAndNevis',
  countrySaintLucia: 'countrySaintLucia',
  countrySaintMartin: 'countrySaintMartin',
  countrySaintPierreAndMiquelon: 'countrySaintPierreAndMiquelon',
  countrySaintVincentAndGrenadines: 'countrySaintVincentAndGrenadines',
  countrySamoa: 'countrySamoa',
  countrySanMarino: 'countrySanMarino',
  countrySaoTomeAndPrincipe: 'countrySaoTomeAndPrincipe',
  countrySaudiArabia: 'countrySaudiArabia',
  countrySenegal: 'countrySenegal',
  countrySerbia: 'countrySerbia',
  countrySeychelles: 'countrySeychelles',
  countrySierraLeone: 'countrySierraLeone',
  countrySingapore: 'countrySingapore',
  countrySintMaarten: 'countrySintMaarten',
  countrySlovakia: 'countrySlovakia',
  countrySlovenia: 'countrySlovenia',
  countrySolomonIslands: 'countrySolomonIslands',
  countrySomalia: 'countrySomalia',
  countrySouthAfrica: 'countrySouthAfrica',
  countrySouthGeorgiaAndSandwichIsl: 'countrySouthGeorgiaAndSandwichIsl',
  countrySouthSudan: 'countrySouthSudan',
  countrySpain: 'countrySpain',
  countrySriLanka: 'countrySriLanka',
  countrySudan: 'countrySudan',
  countrySuriname: 'countrySuriname',
  countrySvalbardAndJanMayen: 'countrySvalbardAndJanMayen',
  countrySwaziland: 'countrySwaziland',
  countrySweden: 'countrySweden',
  countrySwitzerland: 'countrySwitzerland',
  countrySyrianArabRepublic: 'countrySyrianArabRepublic',
  countryTaiwan: 'countryTaiwan',
  countryTajikistan: 'countryTajikistan',
  countryTanzania: 'countryTanzania',
  countryThailand: 'countryThailand',
  countryTimorLeste: 'countryTimorLeste',
  countryTogo: 'countryTogo',
  countryTokelau: 'countryTokelau',
  countryTonga: 'countryTonga',
  countryTrinidadAndTobago: 'countryTrinidadAndTobago',
  countryTunisia: 'countryTunisia',
  countryTurkey: 'countryTurkey',
  countryTurkmenistan: 'countryTurkmenistan',
  countryTurksAndCaicosIslands: 'countryTurksAndCaicosIslands',
  countryTuvalu: 'countryTuvalu',
  countryUganda: 'countryUganda',
  countryUkraine: 'countryUkraine',
  countryUnitedArabEmirates: 'countryUnitedArabEmirates',
  countryUnitedKingdom: 'countryUnitedKingdom',
  countryUnitedStates: 'countryUnitedStates',
  countryUnitedStatesOutlyingIslands: 'countryUnitedStatesOutlyingIslands',
  countryUruguay: 'countryUruguay',
  countryUzbekistan: 'countryUzbekistan',
  countryVanuatu: 'countryVanuatu',
  countryVenezuela: 'countryVenezuela',
  countryVietNam: 'countryVietNam',
  countryVirginIslandsBritish: 'countryVirginIslandsBritish',
  countryVirginIslandsUs: 'countryVirginIslandsUs',
  countryWallisAndFutuna: 'countryWallisAndFutuna',
  countryWesternSahara: 'countryWesternSahara',
  countryYemen: 'countryYemen',
  countryZambia: 'countryZambia',
  countryZimbabwe: 'countryZimbabwe',
  creditor: 'creditor',
  creditorDetails: 'creditorDetails',
  creditorFillInAddressFieldError: 'creditorFillInAddressFieldError',
  creditorName: 'creditorName',
  creditorNonPrefilledText: 'creditorNonPrefilledText',
  creditorPrefilledText: 'creditorPrefilledText',
  customerNoEnterYourText: 'customerNoEnterYourText',
  customerNoText: 'customerNoText',
  darkMode: 'darkMode',
  dataAnalyticsEnabledText: 'dataAnalyticsEnabledText',
  dataAnalyticsTermsText: 'dataAnalyticsTermsText',
  dateOfBirthDateText: 'dateOfBirthDateText',
  dateOfBirthErrorDateInvalid: 'dateOfBirthErrorDateInvalid',
  dateOfBirthErrorDateRequired: 'dateOfBirthErrorDateRequired',
  dateOfBirthErrorInvalid: 'dateOfBirthErrorInvalid',
  dateOfBirthErrorInvalidAge: 'dateOfBirthErrorInvalidAge',
  dateOfBirthErrorMonthRequired: 'dateOfBirthErrorMonthRequired',
  dateOfBirthErrorRequired: 'dateOfBirthErrorRequired',
  dateOfBirthErrorYearMinInvalid: 'dateOfBirthErrorYearMinInvalid',
  dateOfBirthErrorYearRequired: 'dateOfBirthErrorYearRequired',
  dateOfBirthInvalidMonth: 'dateOfBirthInvalidMonth',
  dateOfBirthInvalidYear: 'dateOfBirthInvalidYear',
  dateOfBirthMonthText: 'dateOfBirthMonthText',
  dateOfBirthTitle: 'dateOfBirthTitle',
  dateOfBirthYearText: 'dateOfBirthYearText',
  datePickerGoToTheNextMonth: 'datePickerGoToTheNextMonth',
  datePickerGoToThePreviousMonth: 'datePickerGoToThePreviousMonth',
  deactivateText: 'deactivateText',
  deactivatedAndIgnoredText: 'deactivatedAndIgnoredText',
  deactivatedOnOldAccountText: 'deactivatedOnOldAccountText',
  deactivatedText: 'deactivatedText',
  decriptionText: 'decriptionText',
  deleteInfoText: 'deleteInfoText',
  deleteText: 'deleteText',
  demoBannerText: 'demoBannerText',
  departmentInputText: 'departmentInputText',
  descriptionText: 'descriptionText',
  detailsText: 'detailsText',
  directDebitNotifyAll: 'directDebitNotifyAll',
  directDebitsCompletedTextOne: 'directDebitsCompletedTextOne',
  directDebitsCompletedTextOther: 'directDebitsCompletedTextOther',
  directDebitsCompletedTextZero: 'directDebitsCompletedTextZero',
  directDebitsCreditorFormHintText: 'directDebitsCreditorFormHintText',
  directDebitsDoneTitleText: 'directDebitsDoneTitleText',
  directDebitsEmptyCaptionText: 'directDebitsEmptyCaptionText',
  directDebitsEmptyTitleText: 'directDebitsEmptyTitleText',
  directDebitsHint: 'directDebitsHint',
  directDebitsNotifyAll: 'directDebitsNotifyAll',
  directDebitsNotifyAllInformText: 'directDebitsNotifyAllInformText',
  directDebitsText: 'directDebitsText',
  directDebitsValidityDateTitle: 'directDebitsValidityDateTitle',
  discard: 'discard',
  dismissText: 'dismissText',
  done: 'done',
  doneText: 'doneText',
  dontIgnore: 'dontIgnore',
  dontIgnoreInfoText: 'dontIgnoreInfoText',
  downloadNotificationText: 'downloadNotificationText',
  downloadPdf: 'downloadPdf',
  downloadReportText: 'downloadReportText',
  downloadSummary: 'downloadSummary',
  downloadSummaryDescription: 'downloadSummaryDescription',
  editText: 'editText',
  emailErrorInvalid: 'emailErrorInvalid',
  emailErrorRequired: 'emailErrorRequired',
  emailOptional: 'emailOptional',
  emailSentText: 'emailSentText',
  emailText: 'emailText',
  emailUpdated: 'emailUpdated',
  enterNewPassword: 'enterNewPassword',
  errorBoundaryMessage: 'errorBoundaryMessage',
  error401: 'error401',
  error500: 'error500',
  errorMessagesUnsupportedIban: 'errorMessagesUnsupportedIban',
  errorReloadButton: 'errorReloadButton',
  errorText: 'errorText',
  exitText: 'exitText',
  firstNameErrorRequired: 'firstNameErrorRequired',
  firstNameText: 'firstNameText',
  footerEncryption: 'footerEncryption',
  footerSecureServer: 'footerSecureServer',
  footerAudits: 'footerAudits',
  footerText: 'footerText',
  forgottenPassword: 'forgottenPassword',
  forgottenPasswordCaption: 'forgottenPasswordCaption',
  fromText: 'fromText',
  genderErrorRequired: 'genderErrorRequired',
  genderTitleText: 'genderTitleText',
  generatingLetterText: 'generatingLetterText',
  gotYourPassword: 'gotYourPassword',
  headerTextCooperation: 'headerTextCooperation',
  headerTextFinleapConnectCooperation: 'headerTextFinleapConnectCooperation',
  helpCentre: 'helpCentre',
  hideTransactionsText: 'hideTransactionsText',
  hintText: 'hintText',
  homeCarouselNextText: 'homeCarouselNextText',
  homeCarouselSignUpText: 'homeCarouselSignUpText',
  homeSlideAccountSwitchCaption: 'homeSlideAccountSwitchCaption',
  homeSlideAccountSwitchTitle: 'homeSlideAccountSwitchTitle',
  homeSlideAnalysisCaption: 'homeSlideAnalysisCaption',
  homeSlideAnalysisCaptionNoSo: 'homeSlideAnalysisCaptionNoSo',
  homeSlideAnalysisTitle: 'homeSlideAnalysisTitle',
  homeSlideRegisterCaption: 'homeSlideRegisterCaption',
  homeSlideRegisterTitle: 'homeSlideRegisterTitle',
  ibanErrorInvalid: 'ibanErrorInvalid',
  ibanErrorRequired: 'ibanErrorRequired',
  ibanText: 'ibanText',
  idCard: 'idCard',
  idCardBackText: 'idCardBackText',
  idCardErrorBack: 'idCardErrorBack',
  idCardErrorFileType: 'idCardErrorFileType',
  idCardErrorFront: 'idCardErrorFront',
  idCardErrorNumber: 'idCardErrorNumber',
  idCardFrontText: 'idCardFrontText',
  idCardInfoText: 'idCardInfoText',
  idCardInstructionText: 'idCardInstructionText',
  idCardModalText: 'idCardModalText',
  idCardNumberInputText: 'idCardNumberInputText',
  idCardRequired: 'idCardRequired',
  idCardTapUploadPhotoLabelText: 'idCardTapUploadPhotoLabelText',
  idCardTitleText: 'idCardTitleText',
  idCardUploadAllNotificationsText: 'idCardUploadAllNotificationsText',
  identifiedTextOne: 'identifiedTextOne',
  identifiedTextOther: 'identifiedTextOther',
  identifiedTextZero: 'identifiedTextZero',
  identifiedsoText: 'identifiedsoText',
  identifyPaymentPartnerFormTitle: 'identifyPaymentPartnerFormTitle',
  identifyText: 'identifyText',
  ignore: 'ignore',
  ignoreText: 'ignoreText',
  ignoredInfoText: 'ignoredInfoText',
  ignoredText: 'ignoredText',
  inactiveTenantMessage: 'inactiveTenantMessage',
  includeBirthdateCheckboxLabel: 'includeBirthdateCheckboxLabel',
  includeBirthdateTooltipText: 'includeBirthdateTooltipText',
  incomingPaymentsCreditorName: 'incomingPaymentsCreditorName',
  incomingPaymentsButtonIgnore: 'incomingPaymentsButtonIgnore',
  incomingPaymentsCompletedTextOne: 'incomingPaymentsCompletedTextOne',
  incomingPaymentsCompletedTextOther: 'incomingPaymentsCompletedTextOther',
  incomingPaymentsCompletedTextZero: 'incomingPaymentsCompletedTextZero',
  incomingPaymentsDoneTitleText: 'incomingPaymentsDoneTitleText',
  incomingPaymentsEmptyCaptionText: 'incomingPaymentsEmptyCaptionText',
  incomingPaymentsEmptyTitleText: 'incomingPaymentsEmptyTitleText',
  incomingPaymentsHint: 'incomingPaymentsHint',
  incomingPaymentsNotify: 'incomingPaymentsNotify',
  incomingPaymentsText: 'incomingPaymentsText',
  incomingPaymentsToAdviceTextOne: 'incomingPaymentsToAdviceTextOne',
  incomingPaymentsToAdviceTextOther: 'incomingPaymentsToAdviceTextOther',
  incomingPaymentsToAdviceTextZero: 'incomingPaymentsToAdviceTextZero',
  incomingPaymentsToBeCompletedTextOne: 'incomingPaymentsToBeCompletedTextOne',
  incomingPaymentsToBeCompletedTextOther: 'incomingPaymentsToBeCompletedTextOther',
  incomingPaymentsToBeCompletedTextZero: 'incomingPaymentsToBeCompletedTextZero',
  informManuallyText: 'informManuallyText',
  initiatePaymentText: 'initiatePaymentText',
  inputInvalidDigitsAllowed: 'inputInvalidDigitsAllowed',
  inputInvalidDigitsLettersSpecialCharsAllowed: 'inputInvalidDigitsLettersSpecialCharsAllowed',
  inputInvalidLength: 'inputInvalidLength',
  inputInvalidLengthMaxOneHundred: 'inputInvalidLengthMaxOneHundred',
  inputInvalidLengthMaxTen: 'inputInvalidLengthMaxTen',
  inputInvalidLengthMaxTwenty: 'inputInvalidLengthMaxTwenty',
  inputInvalidLettersAllowed: 'inputInvalidLettersAllowed',
  inputInvalidLettersAndDigitsAllowed: 'inputInvalidLettersAndDigitsAllowed',
  inputInvalidLettersAndDotAllowed: 'inputInvalidLettersAndDotAllowed',
  inputInvalidLettersHyphenAndDotAllowed: 'inputInvalidLettersHyphenAndDotAllowed',
  instructions: 'instructions',
  instructionsText: 'instructionsText',
  intervalText: 'intervalText',
  introduceElCodigoDeVerificacionQueSeLeHaEnviadoAlMovilText:
    'introduceElCodigoDeVerificacionQueSeLeHaEnviadoAlMovilText',
  itanText: 'itanText',
  keepInOldAccount: 'keepInOldAccount',
  languageMenuCatalan: 'languageMenuCatalan',
  languageMenuCatalanNative: 'languageMenuCatalanNative',
  languageMenuEnglish: 'languageMenuEnglish',
  languageMenuEnglishNative: 'languageMenuEnglishNative',
  languageMenuGerman: 'languageMenuGerman',
  languageMenuGermanNative: 'languageMenuGermanNative',
  languageMenuSpanish: 'languageMenuSpanish',
  languageMenuSpanishNative: 'languageMenuSpanishNative',
  lastNameErrorRequired: 'lastNameErrorRequired',
  lastNameText: 'lastNameText',
  letterHasBeenSentOne: 'letterHasBeenSentOne',
  letterHasBeenSentOther: 'letterHasBeenSentOther',
  letterHasBeenSentZero: 'letterHasBeenSentZero',
  letterSentText: 'letterSentText',
  lightMode: 'lightMode',
  loginButtonText: 'loginButtonText',
  loginCaption: 'loginCaption',
  loginForgotPasswordText: 'loginForgotPasswordText',
  loginHelperNote: 'loginHelperNote',
  loginNotBeenHereBefore: 'loginNotBeenHereBefore',
  loginSignUpText: 'loginSignUpText',
  loginTitle: 'loginTitle',
  mandateDeleteText: 'mandateDeleteText',
  mandateIgnoreText: 'mandateIgnoreText',
  manageAccount: 'manageAccount',
  manualAccountSwitch: 'manualAccountSwitch',
  manualAccountSwitchOverviewHintText: 'manualAccountSwitchOverviewHintText',
  manualAccountSwitchText: 'manualAccountSwitchText',
  manualAccountSwitchWelcomeText: 'manualAccountSwitchWelcomeText',
  manualDirectDebitText: 'manualDirectDebitText',
  manualMandateHintText: 'manualMandateHintText',
  manualSwitchDirectDebitsCreditLeasingText: 'manualSwitchDirectDebitsCreditLeasingText',
  manualSwitchDirectDebitsCultureText: 'manualSwitchDirectDebitsCultureText',
  manualSwitchDirectDebitsEducationText: 'manualSwitchDirectDebitsEducationText',
  manualSwitchDirectDebitsEverydayGoodsText: 'manualSwitchDirectDebitsEverydayGoodsText',
  manualSwitchDirectDebitsInsuranceAndFinancesText:
    'manualSwitchDirectDebitsInsuranceAndFinancesText',
  manualSwitchDirectDebitsInsuranceText: 'manualSwitchDirectDebitsInsuranceText',
  manualSwitchDirectDebitsLandlineText: 'manualSwitchDirectDebitsLandlineText',
  manualSwitchDirectDebitsLifeAndLeisureTitleText:
    'manualSwitchDirectDebitsLifeAndLeisureTitleText',
  manualSwitchDirectDebitsLiveTitleText: 'manualSwitchDirectDebitsLiveTitleText',
  manualSwitchDirectDebitsMobileServiceText: 'manualSwitchDirectDebitsMobileServiceText',
  manualSwitchDirectDebitsPropertyManagementText: 'manualSwitchDirectDebitsPropertyManagementText',
  manualSwitchDirectDebitsSectionText: 'manualSwitchDirectDebitsSectionText',
  manualSwitchDirectDebitsSportText: 'manualSwitchDirectDebitsSportText',
  manualSwitchDirectDebitsSubscriptionsText: 'manualSwitchDirectDebitsSubscriptionsText',
  manualSwitchDirectDebitsTransportText: 'manualSwitchDirectDebitsTransportText',
  manualSwitchDirectDebitsUtilitiesText: 'manualSwitchDirectDebitsUtilitiesText',
  manualSwitchEmployerAddMoreCreditorsText: 'manualSwitchEmployerAddMoreCreditorsText',
  manualSwitchEmployerCityPlaceholderText: 'manualSwitchEmployerCityPlaceholderText',
  manualSwitchEmployerCompanyNamePlaceholderText: 'manualSwitchEmployerCompanyNamePlaceholderText',
  manualSwitchEmployerNumberPlaceholderText: 'manualSwitchEmployerNumberPlaceholderText',
  manualSwitchEmployerPostcodePlaceholderText: 'manualSwitchEmployerPostcodePlaceholderText',
  manualSwitchEmployerSectionText: 'manualSwitchEmployerSectionText',
  manualSwitchEmployerStreetPlaceholderText: 'manualSwitchEmployerStreetPlaceholderText',
  manualSwitchIncomingPaymentsAuthoritiesText: 'manualSwitchIncomingPaymentsAuthoritiesText',
  manualSwitchIncomingPaymentsEmployerText: 'manualSwitchIncomingPaymentsEmployerText',
  manualSwitchIncomingPaymentsOtherText: 'manualSwitchIncomingPaymentsOtherText',
  manualSwitchIncomingPaymentsPensionOfficeText: 'manualSwitchIncomingPaymentsPensionOfficeText',
  manualSwitchIncomingPaymentsSectionText: 'manualSwitchIncomingPaymentsSectionText',
  manualSwitchIncomingPaymentsSupportText: 'manualSwitchIncomingPaymentsSupportText',
  manualSwitchPublicAuthoritiesText: 'manualSwitchPublicAuthoritiesText',
  manualSwitchUtilitiesAddCreditorText: 'manualSwitchUtilitiesAddCreditorText',
  manualSwitchUtilitiesSearchByNamePlaceholderText:
    'manualSwitchUtilitiesSearchByNamePlaceholderText',
  manualSwitchUtilitiesSectionText: 'manualSwitchUtilitiesSectionText',
  manualSwitchUtilitiesSignatureInstructionText: 'manualSwitchUtilitiesSignatureInstructionText',
  manualUpdateFormTitle: 'manualUpdateFormTitle',
  manualUpdatesTextOne: 'manualUpdatesTextOne',
  manualUpdatesTextOther: 'manualUpdatesTextOther',
  manualUpdatesTextZero: 'manualUpdatesTextZero',
  manualUpdatesWarningTextOne: 'manualUpdatesWarningTextOne',
  manualUpdatesWarningTextOther: 'manualUpdatesWarningTextOther',
  manualUpdatesWarningTextZero: 'manualUpdatesWarningTextZero',
  markAsDoneText: 'markAsDoneText',
  menuText: 'menuText',
  mfaDecoupledTypeInfo: 'mfaDecoupledTypeInfo',
  mfaHelpText: 'mfaHelpText',
  mfaImagePlaceholderText: 'mfaImagePlaceholderText',
  mfaStatusInvalidText: 'mfaStatusInvalidText',
  mfaTypeNotSupported: 'mfaTypeNotSupported',
  missingCreditorsCaptionText: 'missingCreditorsCaptionText',
  missingCreditorsHeadingText: 'missingCreditorsHeadingText',
  missingCreditorsTitleText: 'missingCreditorsTitleText',
  missingIbanText: 'missingIbanText',
  monthApril: 'monthApril',
  monthAugust: 'monthAugust',
  monthDecember: 'monthDecember',
  monthFebruary: 'monthFebruary',
  monthJanuary: 'monthJanuary',
  monthJuly: 'monthJuly',
  monthJune: 'monthJune',
  monthMarch: 'monthMarch',
  monthMay: 'monthMay',
  monthNovember: 'monthNovember',
  monthOctober: 'monthOctober',
  monthSeptember: 'monthSeptember',
  monthly: 'monthly',
  moreText: 'moreText',
  mrs: 'mrs',
  navigationAccountSwitchText: 'navigationAccountSwitchText',
  navigationChangeLanguage: 'navigationChangeLanguage',
  navigationCloseAccount: 'navigationCloseAccount',
  navigationDirectDebits: 'navigationDirectDebits',
  navigationEmailUpdated: 'navigationEmailUpdated',
  navigationIncomingPayments: 'navigationIncomingPayments',
  navigationOverview: 'navigationOverview',
  navigationPersonalDetails: 'navigationPersonalDetails',
  navigationStandingOrders: 'navigationStandingOrders',
  navigationTransferBalance: 'navigationTransferBalance',
  navigationUpdateChangeEmail: 'navigationUpdateChangeEmail',
  navigationUpdateEmailPassword: 'navigationUpdateEmailPassword',
  networkUnavailableText: 'networkUnavailableText',
  newCustomerText: 'newCustomerText',
  newPassword: 'newPassword',
  nextExecutionDate: 'nextExecutionDate',
  noCreditorText: 'noCreditorText',
  noSignatureText: 'noSignatureText',
  noTransactionsText: 'noTransactionsText',
  noneAddedText: 'noneAddedText',
  noneFoundText: 'noneFoundText',
  noResultsFound: 'noResultsFound',
  noResultsFoundHint: 'noResultsFoundHint',
  notDoneText: 'notDoneText',
  notificationSent: 'notificationSent',
  notify: 'notify',
  notifyAll: 'notifyAll',
  notifyAllDirectDebitsInformationCustomerNoText: 'notifyAllDirectDebitsInformationCustomerNoText',
  notifyPaymentHintText: 'notifyPaymentHintText',
  notifyPaymentPartnerFormTitle: 'notifyPaymentPartnerFormTitle',
  notifyText: 'notifyText',
  numeroDeDocumentoText: 'numeroDeDocumentoText',
  ofText: 'ofText',
  oldBankText: 'oldBankText',
  oldPassword: 'oldPassword',
  onboardingProgressBarTitle: 'onboardingProgressBarTitle',
  onText: 'onText',
  onlineBanking: 'onlineBanking',
  onlineBankingText: 'onlineBankingText',
  optionalText: 'optionalText',
  otherCreditor: 'otherCreditor',
  otherInformation: 'otherInformation',
  otpErrorRequired: 'otpErrorRequired',
  otroDocumentoExtranjeroText: 'otroDocumentoExtranjeroText',
  overviewBackButtonText: 'overviewBackButtonText',
  overviewEvobankPageTitle: 'overviewEvobankPageTitle',
  overviewHint: 'overviewHint',
  overviewPageCaption: 'overviewPageCaption',
  overviewPageTitle: 'overviewPageTitle',
  pasaporteEspanolText: 'pasaporteEspanolText',
  passwordAtLeastOneDigitText: 'passwordAtLeastOneDigitText',
  passwordAtLeastOneLowerCaseText: 'passwordAtLeastOneLowerCaseText',
  passwordAtLeastOneUpperCaseText: 'passwordAtLeastOneUpperCaseText',
  passwordAtLeastOneSpecialText: 'passwordAtLeastOneSpecialText',
  passwordErrorInvalid: 'passwordErrorInvalid',
  passwordErrorInvalidLength: 'passwordErrorInvalidLength',
  passwordErrorMismatch: 'passwordErrorMismatch',
  passwordErrorNewEqualToOldOne: 'passwordErrorNewEqualToOldOne',
  passwordErrorRequired: 'passwordErrorRequired',
  passwordMinLengthText: 'passwordMinLengthText',
  passwordMustContainText: 'passwordMustContainText',
  passwordNewInstructions: 'passwordNewInstructions',
  passwordSuccessText: 'passwordSuccessText',
  passwordText: 'passwordText',
  passwordUpdated: 'passwordUpdated',
  paymentPartnerAddressInformation: 'paymentPartnerAddressInformation',
  paymentPartnerDetailsUpdated: 'paymentPartnerDetailsUpdated',
  pendingText: 'pendingText',
  personal: 'personal',
  personalDetails: 'personalDetails',
  personalDetailsCaption: 'personalDetailsCaption',
  personalDetailsTitle: 'personalDetailsTitle',
  personalDetailsSmeTitle: 'personalDetailsSmeTitle',
  personalDetailsUpdatedText: 'personalDetailsUpdatedText',
  phoneNumberCountryCode: 'phoneNumberCountryCode',
  phoneNumberError: 'phoneNumberError',
  phoneNumberNational: 'phoneNumberNational',
  phoneNumberText: 'phoneNumberText',
  pinCodeText: 'pinCodeText',
  pinEnterYourPinText: 'pinEnterYourPinText',
  pinErrorRequired: 'pinErrorRequired',
  progressText: 'progressText',
  purposeDetailText: 'purposeDetailText',
  purposeText: 'purposeText',
  readMoreText: 'readMoreText',
  recipientText: 'recipientText',
  recreateText: 'recreateText',
  recreatedText: 'recreatedText',
  regShieldError: 'regShieldError',
  regShieldError10000: 'regShieldError10000',
  regShieldError10005: 'regShieldError10005',
  regShieldError10006: 'regShieldError10006',
  regShieldError10009: 'regShieldError10009',
  regShieldError10010: 'regShieldError10010',
  regShieldError10012: 'regShieldError10012',
  regShieldError10015: 'regShieldError10015',
  regShieldError10016: 'regShieldError10016',
  regShieldError10017: 'regShieldError10017',
  regShieldError11000: 'regShieldError11000',
  regShieldError20XXX: 'regShieldError20XXX',
  regShieldError20Xxx: 'regShieldError20Xxx',
  regShieldError30000: 'regShieldError30000',
  regShieldError40XXX: 'regShieldError40XXX',
  regShieldError40Xxx: 'regShieldError40Xxx',
  regShieldError90000: 'regShieldError90000',
  regShieldErrorTryAgain: 'regShieldErrorTryAgain',
  registerAccountAnalysisMultipleAccountDescriptionText:
    'registerAccountAnalysisMultipleAccountDescriptionText',
  registerAccountAnalysisSelectionButtonText: 'registerAccountAnalysisSelectionButtonText',
  registerAccountAnalyzingText: 'registerAccountAnalyzingText',
  registerAccountAnalyzingTitle: 'registerAccountAnalyzingTitle',
  registerBankSelectionInputText: 'registerBankSelectionInputText',
  registerManualSwitchBankSelectionDescriptionText:
    'registerManualSwitchBankSelectionDescriptionText',
  registerManualSwitchBankSelectionInputText: 'registerManualSwitchBankSelectionInputText',
  registerOldBankCaption: 'registerOldBankCaption',
  registerOldBankLoginText: 'registerOldBankLoginText',
  registerOldBankTitle: 'registerOldBankTitle',
  registrationStatusFailedText: 'registrationStatusFailedText',
  registrationStatusInvalidText: 'registrationStatusInvalidText',
  registrationStatusPendingText: 'registrationStatusPendingText',
  regshieldAccountMismatchErrorText: 'regshieldAccountMismatchErrorText',
  requiredText: 'requiredText',
  resetPassword: 'resetPassword',
  restrictTenantMessage: 'restrictTenantMessage',
  save: 'save',
  saveContinue: 'saveContinue',
  searchText: 'searchText',
  selectedPaymentPartnerTextOne: 'selectedPaymentPartnerTextOne',
  selectedPaymentPartnerTextOther: 'selectedPaymentPartnerTextOther',
  sendPassword: 'sendPassword',
  senderText: 'senderText',
  showLessText: 'showLessText',
  showMoreText: 'showMoreText',
  showPdf: 'showPdf',
  showText: 'showText',
  signOnSmartPhoneText: 'signOnSmartPhoneText',
  signOnSmartPhoneTitleText: 'signOnSmartPhoneTitleText',
  signOutCancelButton: 'signOutCancelButton',
  signOutContinueButton: 'signOutContinueButton',
  signOutMessage: 'signOutMessage',
  signOutTitle: 'signOutTitle',
  signUpIbanCaption: 'signUpIbanCaption',
  signUpIbanInput: 'signUpIbanInput',
  signUpIbanTitle: 'signUpIbanTitle',
  signUpOtpInput: 'signUpOtpInput',
  signUpPersonalDetailsCaption: 'signUpPersonalDetailsCaption',
  signWithMouseText: 'signWithMouseText',
  signature: 'signature',
  signatureButtonClear: 'signatureButtonClear',
  signatureCanvasInstruction: 'signatureCanvasInstruction',
  signatureErrorRequired: 'signatureErrorRequired',
  signatureInfoText: 'signatureInfoText',
  signatureInstructionText: 'signatureInstructionText',
  signatureModalText: 'signatureModalText',
  signatureTitleText: 'signatureTitleText',
  signatureUploadedText: 'signatureUploadedText',
  signupLoginDetailsCaption: 'signupLoginDetailsCaption',
  signupLoginDetailsTitle: 'signupLoginDetailsTitle',
  sir: 'sir',
  smsForSignatureLinkText: 'smsForSignartureLinkText',
  smsSentText: 'smsSentText',
  standingOrderDeactivateOldDescriptionText: 'standingOrderDeactivateOldDescriptionText',
  standingOrderDeactivateOnOldAccountText: 'standingOrderDeactivateOnOldAccountText',
  standingOrdersCompleted: 'standingOrdersCompleted',
  standingOrdersCompletedOne: 'standingOrdersCompletedOne',
  standingOrdersCompletedOther: 'standingOrdersCompletedOther',
  standingOrdersCompletedZero: 'standingOrdersCompletedZero',
  standingOrdersDetailsTitle: 'standingOrdersDetailsTitle',
  standingOrdersDoneTitleText: 'standingOrdersDoneTitleText',
  standingOrdersEmptyCaptionText: 'standingOrdersEmptyCaptionText',
  standingOrdersEmptyTitleText: 'standingOrdersEmptyTitleText',
  standingOrdersHint: 'standingOrdersHint',
  standingOrdersInstructions: 'standingOrdersInstructions',
  standingOrdersMarkAsDone: 'standingOrdersMarkAsDone',
  standingOrdersPsd2Text: 'standingOrdersPsd2Text',
  standingOrdersText: 'standingOrdersText',
  standingOrdersToBeCompletedText: 'standingOrdersToBeCompletedText',
  standingOrdersToBeCompletedTextOne: 'standingOrdersToBeCompletedTextOne',
  standingOrdersToBeCompletedTextOther: 'standingOrdersToBeCompletedTextOther',
  standingOrdersToBeCompletedTextZero: 'standingOrdersToBeCompletedTextZero',
  startBankAccountSwitch: 'startBankAccountSwitch',
  successBackButtonText: 'successBackButtonText',
  successfulText: 'successfulText',
  tanErrorRequired: 'tanErrorRequired',
  tanSchemeErrorRequired: 'tanSchemeErrorRequired',
  tanSelectText: 'tanSelectText',
  tanSelectionErrorRequired: 'tanSelectionErrorRequired',
  tanSelectionText: 'tanSelectionText',
  tenantDisabledUserRegistration: 'tenantDisabledUserRegistration',
  tenantErrorText: 'tenantErrorText',
  termsAndConditionsFinreachCheckboxSpecificDataText:
    'termsAndConditionsFinreachCheckboxSpecificDataText',
  termsAndConditionsFinreachCheckboxText: 'termsAndConditionsFinreachCheckboxText',
  termsAndConditionsFinreachTooltipText: 'termsAndConditionsFinreachTooltipText',
  textsFaq: 'textsFaq',
  textsFaqTitle: 'textsFaqTitle',
  textsImprint: 'textsImprint',
  textsImprintTitle: 'textsImprintTitle',
  textsPrivacy: 'textsPrivacy',
  textsTos: 'textsTos',
  textsTosShort: 'textsTosShort',
  tipoDeDocumentoText: 'tipoDeDocumentoText',
  toText: 'toText',
  todayText: 'todayText',
  transactionText: 'transactionText',
  transactionsText: 'transactionsText',
  transfer: 'transfer',
  transferBalanceCurrentBalanceText: 'transferBalanceCurrentBalanceText',
  transferBalanceEnterYourPinText: 'transferBalanceEnterYourPinText',
  transferBalanceHint: 'transferBalanceHint',
  transferBalanceInfoText: 'transferBalanceInfoText',
  transferBalanceNotSupportedText: 'transferBalanceNotSupportedText',
  transferBalanceReceiverText: 'transferBalanceReceiverText',
  transferBalanceRemainingTransferText: 'transferBalanceRemainingTransferText',
  transferBalanceSelectTanText: 'transferBalanceSelectTanText',
  transferBalanceTanSelectionText: 'transferBalanceTanSelectionText',
  transferBalanceText: 'transferBalanceText',
  transferBalanceUsageBalanceTransferText: 'transferBalanceUsageBalanceTransferText',
  transferBalanceUsageText: 'transferBalanceUsageText',
  transferNowText: 'transferNowText',
  transferSuccessfulText: 'transferSuccessfulText',
  transferred: 'transferred',
  ubertragungssaldoSelectTanText: 'ubertragungssaldoSelectTanText',
  unauthorizedError: 'unauthorizedError',
  unmatchedText: 'unmatchedText',
  unmatchedWarningTextOne: 'unmatchedWarningTextOne',
  unmatchedWarningTextOther: 'unmatchedWarningTextOther',
  unmatchedWarningTextZero: 'unmatchedWarningTextZero',
  updateText: 'updateText',
  uploadText: 'uploadText',
  usernameText: 'userNameText',
  utilities: 'utilities',
  validityDateNoteText: 'validityDateNoteText',
  validityDateSubTitleText: 'validityDateSubTitleText',
  validityDateTitleText: 'validityDateTitleText',
  viewDetailsText: 'viewDetailsText',
  welcomeBankText: 'welcomeBankText',
  welcomeEvobankText: 'welcomeEvobankText',
  welcomeText: 'welcomeText'
} as const;
